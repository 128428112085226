import React from 'react'
import { Link } from 'gatsby'

import { Box, Card, CardBody, Heading, Image } from 'grommet'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SinglePhotoNode } from '../hooks/useGallery'

import styled from 'styled-components'

const StyledImage = styled(GatsbyImage)`
  // filter: sepia(1);
  // filter: blur(2px);

  transition: transform 0.5s ease-in-out;
  :hover {
    transform: scale(1.1);
    filter: saturate(1.3);
  }
`
const StyledLink = styled(Link)`
  color: palevioletred;
  font-weight: bold;
`

export default function GalleryItem({ image }: { image: SinglePhotoNode }) {
  return (
    <Box pad="medium" gap="none">
      <StyledLink to={image.fields.imageUrlPath} style={{ color: 'white' }}>
        <Card background="background-front" elevation="none" round="xsmall">
          <CardBody style={{ maxHeight: 200, minHeight: 200 }}>
            <StyledImage
              alt="image"
              loading="lazy"
              image={image.childImageSharp.gatsbyImageData}
              // srcSet={image.childImageSharp.fluid.srcSet}
              // src={image.childImageSharp.fixed.src} a11yTitle={image.fields.metadata.Title}
            />
          </CardBody>
          <Box pad={{ horizontal: 'medium' }}>
            <Heading level="4" margin={{ vertical: 'medium' }} truncate>
              {image.fields.metadata.Title}
            </Heading>
            {/* <Paragraph margin={{ top: 'none' }}>
            {image.fields.metadata.Caption}
          </Paragraph> */}
          </Box>
        </Card>
      </StyledLink>
    </Box>
  )
}
