import { Grid, ResponsiveContext } from 'grommet'
import React from 'react'
import useGallery from '../hooks/useGallery'
import GalleryItem from './GalleryItem'

export default function Gallery() {
  const size = React.useContext(ResponsiveContext)

  const images = useGallery()
  return (
    <Grid columns={size !== 'small' ? 'medium' : '100%'} gap="small" pad="small">
      {images.map((image) => {
        return <GalleryItem key={image.fields.cid} image={image} />
      })}
    </Grid>
  )
}
