import { Box } from 'grommet'
import * as React from 'react'
import Gallery from '../components/Gallery'
import HeaderComponent from '../components/Header'

const IndexPage = () => {
  return (
    <Box fill rows={['xsmall', 'auto']}>
      <HeaderComponent />
      <Gallery />
    </Box>
  )
}

export default IndexPage
