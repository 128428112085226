import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export interface SinglePhotoNode {
  id: string
  name: string
  publicURL: string
  childImageSharp: {
    gatsbyImageData: any
  }
  fields: {
    cid: string
    imageUrlPath: string
    metadata: {
      Keywords: string
      Title: string
      Caption: string
      Creator: string
      CreatedDate: string
      Copyright: string
      Rights: string
      CopyrightNotice: string
    }
  }
}
interface Query {
  allFile: {
    nodes: SinglePhotoNode[]
  }
}
export default function useGallery() {
  const data = useStaticQuery<Query>(graphql`
    query GalleryItems {
      allFile(
        filter: { sourceInstanceName: { eq: "photos" } }
        sort: { fields: fields___metadata___CreatedDate, order: DESC }
      ) {
        nodes {
          fields {
            cid
            imageUrlPath
            metadata {
              Title
            }
          }
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, height: 200)
          }
        }
      }
    }
  `)
  return data.allFile.nodes
}
