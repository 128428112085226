import { graphql, useStaticQuery } from 'gatsby'
import { Anchor, Box, Menu, ResponsiveContext } from 'grommet'
import { Grommet as GrommetIcon, Menu as MenuIcon } from 'grommet-icons'
import React from 'react'
import HeaderLayout from '../layouts/HeaderLayout'
export default function HeaderComponent() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <HeaderLayout>
      <Anchor
        href="/"
        icon={<GrommetIcon color="brand" />}
        label={data.site.siteMetadata.title}
      />
      <ResponsiveContext.Consumer>
        {(size) =>
          size === 'small' ? (
            <Box justify="end">
              <Menu
                a11yTitle="Navigation Menu"
                dropProps={{ align: { top: 'bottom', right: 'right' } }}
                icon={<MenuIcon color="brand" />}
                items={[
                  {
                    label: <Box pad="small">Coding blog</Box>,
                    href: 'https://woss.io/',
                  },
                  {
                    label: <Box pad="small">Twitter</Box>,
                    href: 'https://twitter.com/woss_io',
                  },
                  {
                    label: <Box pad="small">Flickr</Box>,
                    href: 'https://www.flickr.com/photos/dwoss/',
                  },
                ]}
              />
            </Box>
          ) : (
            <Box justify="end" direction="row" gap="medium">
              <Anchor href="https://woss.io/" label="Coding blog" />
              <Anchor href="https://twitter.com/woss_io" label="Twitter" />
              <Anchor href="https://www.flickr.com/photos/dwoss/" label="Flickr" />
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </HeaderLayout>
  )
}
